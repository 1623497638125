import React from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// routes
import { usePathname } from "src/routes/hook";
// components
import Button from "src/components/button";
import Iconify from "src/components/iconify";
import { LogoFull, LogoSingle } from "src/components/logo";
// assets
import CV from "src/assets/resume.pdf";
//
import NavMobile from "./nav/mobile";
import NavDesktop from "./nav/desktop";
import { navConfig } from "./config-navigation";

// ----------------------------------------------------------------------

export default function Header() {
  const upSm = useResponsive("up", "sm");

  const upLg = useResponsive("up", "lg");

  const pathname = usePathname();

  const isHome = pathname === "/";

  const showOnScroll = () => {
    const cv = document.getElementById("download")?.classList;

    if (window.scrollY > window.innerHeight) {
      cv?.add("show");
    } else {
      cv?.remove("show");
    }
  };

  window.addEventListener("scroll", showOnScroll);

  return (
    <Box component="header" className="header_container">
      <Stack
        className="container"
        direction="row"
        alignItems="center"
        sx={{
          height: "inherit",
          flexShrink: 0,
        }}
      >
        {upSm ? (
          <LogoFull sx={{ alignSelf: "center" }} />
        ) : (
          <LogoSingle sx={{ alignSelf: "center" }} />
        )}

        {upLg && <NavDesktop data={navConfig} />}

        <Box flex={1} width={1} />

        {isHome && (
          <Box id="download" className="header-dl">
            <Button
              href={CV}
              download
              label="Resume"
              startIcon={<Iconify icon="material-symbols:download" />}
            />
          </Box>
        )}

        {!upLg && <NavMobile data={navConfig} />}
      </Stack>
    </Box>
  );
}
