import React, { forwardRef } from "react";
// @mui
import Link from "@mui/material/Link";
import Box, { BoxProps } from "@mui/material/Box";
// routes
import { RouterLink } from "src/routes/components";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoSingle = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const renderLogo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: "max-content",
          height: 60,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 512 512"
        >
          <g fill="none">
            <path
              id="shape2"
              d="M354.461 101L512 411H196.924L354.461 101Z"
              fill="#FFFFFF"
            />
            <path
              id="shape1"
              d="M157.538 411L0 101L315.076 101L157.538 411Z"
              fill="#A349A3"
            />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return renderLogo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
        {renderLogo}
      </Link>
    );
  }
);

export default LogoSingle;
