import { lazy } from "react";
import { Outlet } from "react-router-dom";
// layouts
import MainLayout from "src/layouts/main";

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("src/pages/home"));
const PortfolioPage = lazy(() => import("src/pages/porfolio"));
const ContactPage = lazy(() => import("src/pages/contact"));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { path: "portfolio", element: <PortfolioPage /> },
      { path: "contact", element: <ContactPage /> },
    ],
  },
];
