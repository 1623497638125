import React from "react";
// @mui
import Box from "@mui/material/Box";
//
import Header from "./header";
import Footer from "./footer";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <Box sx={{ height: 1, display: "flex", flexDirection: "column" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Header />

        {children}

        <Footer />
      </Box>
    </Box>
  );
}
