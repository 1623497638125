import React, { forwardRef } from "react";
// @mui
import Link from "@mui/material/Link";
import Box, { BoxProps } from "@mui/material/Box";
// routes
import { RouterLink } from "src/routes/components";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoFull = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const renderLogo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: "max-content",
          height: 18,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 2130 128"
        >
          <g fill="none">
            <g id="group2">
              <path
                id="text8_1"
                d="M2084.13 81.891H2065.29V126.753H2034.89V1.7803H2084.13C2114.71 1.7803 2130 19.0486 2130 42.0136C2130 62.6645 2115.95 81.891 2084.13 81.891ZM2081.82 57.6805C2093.91 57.6805 2099.07 51.6268 2099.07 42.0136C2099.07 32.4003 2093.91 26.3477 2081.82 26.3477H2065.29V57.6805H2081.82Z"
                fill="#FFFFFF"
              />
              <path
                id="text7_1"
                d="M1954.19 128C1918.81 128 1890.01 101.474 1890.01 63.911C1890.01 26.3477 1918.81 0 1954.19 0C1989.74 0 2018.01 26.3477 2018.01 63.911C2018.01 101.474 1989.56 128 1954.19 128ZM1954.19 100.228C1974.1 100.228 1987.08 85.8075 1987.08 63.911C1987.08 41.6577 1974.1 27.5942 1954.19 27.5942C1933.92 27.5942 1921.12 41.6577 1921.12 63.911C1921.12 85.8075 1933.92 100.228 1954.19 100.228Z"
                fill="#FFFFFF"
              />
              <path
                id="text6_1"
                d="M1842.91 75.3046H1795.62V126.753H1765.22V1.7803H1795.62V50.7372H1842.91V1.7803H1873.31V126.753H1842.91V75.3046Z"
                fill="#FFFFFF"
              />
              <path
                id="text5_1"
                d="M1701.05 128C1674.2 128 1653.22 114.648 1652.34 89.3681H1684.69C1685.58 98.9813 1691.63 103.611 1700.16 103.611C1709.05 103.611 1714.74 99.1593 1714.74 91.8611C1714.74 68.7171 1652.34 81.1793 1652.69 36.8516C1652.69 13.1739 1672.07 0 1698.2 0C1725.4 0 1743.72 13.5297 1744.96 37.3854H1712.07C1711.54 29.3745 1705.85 24.5674 1697.67 24.3895C1690.38 24.2115 1684.87 27.9501 1684.87 35.783C1684.87 57.5025 1746.56 47.7104 1746.56 89.546C1746.56 110.553 1730.2 128 1701.05 128Z"
                fill="#FFFFFF"
              />
              <path
                id="text4_1"
                d="M1562.39 71.566V126.753H1531.99V1.7803H1562.39V56.6119L1603.99 1.7803H1639.72L1591.37 63.0204L1641.5 126.753H1604.35L1562.39 71.566Z"
                fill="#FFFFFF"
              />
              <path
                id="text3_1"
                d="M1467.11 1.7803C1497.69 1.7803 1512.98 19.4044 1512.98 41.1239C1512.98 56.7898 1504.44 71.566 1485.24 77.2628L1514.05 126.753H1479.73L1453.78 79.5769H1446.49V126.753H1416.09V1.7803H1467.11ZM1465.33 27.0604H1446.49V58.0363H1465.33C1476.71 58.0363 1482.04 52.1616 1482.04 42.3705C1482.04 33.1131 1476.71 27.0604 1465.33 27.0604Z"
                fill="#FFFFFF"
              />
              <path
                id="text2_1"
                d="M1335.39 128C1300.01 128 1271.21 101.474 1271.21 63.911C1271.21 26.3477 1300.01 0 1335.39 0C1370.94 0 1399.21 26.3477 1399.21 63.911C1399.21 101.474 1370.77 128 1335.39 128ZM1335.39 100.228C1355.3 100.228 1368.28 85.8075 1368.28 63.911C1368.28 41.6577 1355.3 27.5942 1335.39 27.5942C1315.12 27.5942 1302.32 41.6577 1302.32 63.911C1302.32 85.8075 1315.12 100.228 1335.39 100.228Z"
                fill="#FFFFFF"
              />
              <path
                id="text1_1"
                d="M1082.78 1.7803H1115.31L1133.27 92.7508L1155.49 1.7803H1188.91L1210.25 92.7508L1228.38 1.7803H1261.09L1228.56 126.753H1191.76L1171.85 44.5066L1151.22 126.753H1114.42L1082.78 1.7803Z"
                fill="#FFFFFF"
              />
            </g>

            <g id="group1">
              <path
                id="text9_2"
                d="M1024.47 1.7803C1055.05 1.7803 1070.34 19.4044 1070.34 41.1239C1070.34 56.7898 1061.81 71.566 1042.61 77.2628L1071.41 126.753H1037.1L1011.14 79.5769H1003.85V126.753H973.45V1.7803H1024.47ZM1022.7 27.0604H1003.85V58.0363H1022.7C1034.07 58.0363 1039.41 52.1616 1039.41 42.3705C1039.41 33.1131 1034.07 27.0604 1022.7 27.0604Z"
                fill="#A349A3"
              />
              <path
                id="text8_2"
                d="M920.126 104.678H873.548L866.081 126.753H834.259L879.415 1.7803H914.616L959.772 126.753H927.593L920.126 104.678ZM896.837 35.4272L881.548 81.1793H912.304L896.837 35.4272Z"
                fill="#A349A3"
              />
              <path
                id="text7_2"
                d="M820.401 1.7803V126.753H790.001L739.156 49.6686V126.753H708.756V1.7803H739.156L790.001 79.2211V1.7803H820.401Z"
                fill="#A349A3"
              />
              <path
                id="text6_2"
                d="M655.429 104.678H608.851L601.384 126.753H569.562L614.719 1.7803H649.919L695.076 126.753H662.896L655.429 104.678ZM632.14 35.4272L616.851 81.1793H647.607L632.14 35.4272Z"
                fill="#A349A3"
              />
              <path
                id="text5_2"
                d="M480.503 1.7803H561.748V26.1698H510.904V52.5175H548.949V76.1942H510.904V126.753H480.503V1.7803Z"
                fill="#A349A3"
              />
              <path
                id="text4_2"
                d="M427.177 104.678H380.599L373.132 126.753H341.31L386.466 1.7803H421.667L466.823 126.753H434.644L427.177 104.678ZM403.888 35.4272L388.599 81.1793H419.355L403.888 35.4272Z"
                fill="#A349A3"
              />
              <path
                id="text3_2"
                d="M285.318 128C258.473 128 237.494 114.648 236.606 89.3681H268.961C269.851 98.9813 275.895 103.611 284.43 103.611C293.318 103.611 299.006 99.1593 299.006 91.8611C299.006 68.7171 236.606 81.1793 236.961 36.8516C236.961 13.1739 256.339 0 282.473 0C309.673 0 327.985 13.5297 329.229 37.3854H296.341C295.808 29.3745 290.118 24.5674 281.94 24.3895C274.651 24.2115 269.141 27.9501 269.141 35.783C269.141 57.5025 330.83 47.7104 330.83 89.546C330.83 110.553 314.473 128 285.318 128Z"
                fill="#A349A3"
              />
              <path
                id="text2_2"
                d="M186.838 104.678H140.259L132.792 126.753H100.97L146.127 1.7803H181.327L226.484 126.753H194.304L186.838 104.678ZM163.548 35.4272L148.259 81.1793H179.015L163.548 35.4272Z"
                fill="#A349A3"
              />
              <path
                id="text1_2"
                d="M54.2237 1.7803H84.6254V86.8761C84.6254 113.758 68.2681 128 43.5565 128C17.9565 128 0 113.046 0 84.9178H30.2229C30.2229 95.5997 34.8458 100.762 42.668 100.762C49.9572 100.762 54.2237 96.1334 54.2237 86.8761V1.7803Z"
                fill="#A349A3"
              />
            </g>
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return renderLogo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
        {renderLogo}
      </Link>
    );
  }
);

export default LogoFull;
