import React from "react";
import { motion as m } from "framer-motion";
// @mui
import Box, { BoxProps } from "@mui/material/Box";

// ----------------------------------------------------------------------

const SplashScreen = ({ sx, ...other }: BoxProps) => {
  const styles = {
    position: "absolute",
    left: 0,
    right: 0,
    width: 50,
    height: 50,
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
  };

  const transition = {
    duration: 1.5,
    repeat: Infinity,
    ease: "linear",
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "var(--color-accent)",
        zIndex: 9998,
        ...sx,
      }}
      {...other}
    >
      <Box
        component={m.div}
        sx={{
          position: "relative",
          width: 50,
          height: 100,
        }}
        transition={transition}
        animate={{
          rotate: [0, 360],
        }}
      >
        <Box
          sx={{
            ...styles,
            top: 0,
            borderTop: "50px solid var(--color-primary)",
          }}
        />

        <Box
          sx={{
            ...styles,
            bottom: 0,
            borderBottom: "50px solid var(--color-white)",
          }}
        />
      </Box>
    </Box>
  );
};

export default SplashScreen;
