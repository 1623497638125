// routes
import { paths } from "src/routes/paths";

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Portfolio",
    path: paths.portfolio,
  },
  {
    title: "Contact & more",
    path: paths.contact,
  },
];
