import React, { useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// hooks
import { usePathname } from "src/routes/hook";
import { useBoolean } from "src/hooks/use-boolean";
// types
import { NavProps } from "../types";
// components
import Iconify from "src/components/iconify";
//
import NavLink from "./nav-link";

// ----------------------------------------------------------------------

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0),
  color: "var(--color-white)",
  transition: "var(--transition)",
  "&:hover": {
    color: "var(--color-light)",
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(4),
  justifyContent: "flex-end",
}));

// ----------------------------------------------------------------------

export default function NavMobile({ data }: NavProps) {
  const open = useBoolean(false);

  const pathname = usePathname();

  useEffect(() => {
    if (open.value) {
      open.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <StyledIconButton disableRipple onClick={open.onToggle} sx={{ ml: 1.5 }}>
        <Iconify icon="material-symbols:menu" width={32} />
      </StyledIconButton>

      <Drawer
        open={open.value}
        onClose={open.onFalse}
        anchor="right"
        PaperProps={{
          sx: {
            width: "80%",
            background: "var(--background-popover)",
            zIndex: 9999,
          },
        }}
      >
        <DrawerHeader>
          <StyledIconButton disableRipple onClick={open.onFalse}>
            <Iconify icon="material-symbols:close" width={32} />
          </StyledIconButton>
        </DrawerHeader>

        <Stack component="nav">
          {data.map((link, index) => (
            <NavLink key={index} item={link} />
          ))}
        </Stack>
      </Drawer>
    </>
  );
}
