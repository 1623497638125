import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
// types
import { ButtonVariantProps } from "./types";

// ----------------------------------------------------------------------

type Props = {
  href: string;
  label?: string;
  download?: boolean;
  isLink?: boolean;
  variant?: ButtonVariantProps;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const Button = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      href,
      label,
      download,
      isLink = false,
      variant = "contained",
      startIcon,
      endIcon,
      ...other
    },
    ref
  ) => {
    const isOutlined = variant === "outlined" && "btn-outlined";

    if (isLink) {
      return (
        <Link ref={ref} to={href} {...other} className={`btn ${isOutlined}`}>
          {startIcon}

          {label}

          {endIcon}
        </Link>
      );
    }

    return (
      <a
        ref={ref}
        href={href}
        download={download}
        className={`btn ${isOutlined}`}
        {...other}
      >
        {startIcon}

        {label}

        {endIcon}
      </a>
    );
  }
);

export default Button;
