import React from "react";
// @mui
import Stack from "@mui/material/Stack";
// types
import { NavProps } from "../types";
//
import NavLink from "./nav-link";

// ----------------------------------------------------------------------

export default function NavDesktop({ data }: NavProps) {
  return (
    <Stack
      component="nav"
      direction="row"
      alignItems="stretch"
      sx={{ ml: 7.75, height: 1 }}
    >
      {data.map((link, index) => (
        <NavLink key={index} item={link} />
      ))}
    </Stack>
  );
}
