import React from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// components
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

const _links = [
  {
    name: "Telegram",
    link: "https://t.me/jasaf_anar",
    icon: "fa-brands:telegram-plane",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/amsolaiman/",
    icon: "fa-brands:linkedin-in",
  },
  {
    name: "GitHub",
    link: "https://github.com/amsolaiman",
    icon: "fa-brands:github",
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const upSm = useResponsive("up", "sm");

  return (
    <Box component="footer" className="footer_container">
      <Stack
        className="container"
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: "inherit",
        }}
      >
        <p>© jasafanar workshop.{!upSm && <br />} All rights reserved.</p>

        <Stack direction="row" spacing={2}>
          {_links.map((item, index) => (
            <a key={index} href={item.link} target="_blank" rel="noreferrer">
              <Iconify icon={item.icon} width={!upSm ? 18 : 20} />
            </a>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
