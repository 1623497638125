import { useEffect } from "react";
// routes
import { usePathname } from "src/routes/hook";

// ----------------------------------------------------------------------

const ScrollToTop = () => {
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
