import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
// @mui
import Box from "@mui/material/Box";
// types
import { NavItemProps } from "../types";

// ----------------------------------------------------------------------

type NavLinkProps = {
  item: NavItemProps;
};

export default function NavLink({ item }: NavLinkProps) {
  const resolvePath = useResolvedPath(item.path);

  const isActive = useMatch({ path: resolvePath.pathname, end: true });

  return (
    <Box className={`navdesktop_link ${isActive && "active"}`}>
      <Link to={item.path}>{item.title}</Link>
    </Box>
  );
}
